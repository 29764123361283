import { Component, OnInit, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';

const MARGIN_OFFSET_PX = 125; // The approximate margin size to account for when resizing signature area.
const MAX_WIDTH_PX = 700; // The maximum signature area width.

@Component({
  selector: 'app-widget-signature',
  templateUrl: './widget-signature.component.html',
  styleUrls: ['./widget-signature.component.scss']
})
export class WidgetSignatureComponent implements OnInit, AfterViewInit {
  @Output() signatureReady: EventEmitter<string> = new EventEmitter();
  @ViewChild('signature') signature: SignaturePad;

  locked = false;
  layoutChanges: Observable<BreakpointState>;
  signatureOptions = {
    canvasWidth: 265, // Good starting size for most iPhones
    canvasHeight: 200,
  };

  isReady = false;

  constructor(breakpointObserver: BreakpointObserver) {
    // Fires on initial subscribe and on rotate.
    this.layoutChanges = breakpointObserver.observe([
      '(orientation: portrait)',
      '(orientation: landscape)',
    ]);
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.layoutChanges.subscribe((result) => {
      // Save data to restore after resize (which clears it)
      const data = this.signature.toData();

      // Calculate new dimensiions
      const windowWidth = Math.min(window.innerWidth, window.outerWidth);
      const newWidth = windowWidth - MARGIN_OFFSET_PX;
      const width = MAX_WIDTH_PX < newWidth ? MAX_WIDTH_PX : newWidth;
      this.signature.set('canvasWidth', width);

      // Restore data
      this.signature.fromData(data);
    });
  }

  // Used in template
  public signatureComplete() {
    console.log('Signature Complete');
    this.isReady = true;
  }

  // Used in template
  public clearSignature() {
    this.signature.clear();
    this.isReady = false;
  }

  public done() {
    // Disable control buttons and prevent signature changes
    this.locked = true;
    this.isReady = false;
    this.signature.off();

    const data = this.signature.toDataURL();
    this.signatureReady.emit(data);
  }
}
