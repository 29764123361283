import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'html'
})
export class HtmlPipe implements PipeTransform {

  newline_regexp: RegExp;

  constructor() {
    const self = this;

    self.newline_regexp = new RegExp('\\n', 'g');
  }

  transform(value: any, args?: any): any {
    const self = this;

    // Newline to <br> replacement
    value = value.replace(self.newline_regexp, '<br>');

    return value;
  }
}
