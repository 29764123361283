import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UUID } from 'angular2-uuid';
import { take, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
// import languages from './languages.json';

@Injectable({
  providedIn: 'root'
})
export class ChatMessagesService {

  session_id: string;
  session_language = 'en-US';

  chat_url: string;
  serviceUrl = document.location.host;

  public output_contexts: Array<string> = [];


  serviceBase = `${document.location.protocol}//${this.serviceUrl}/api/`;
  // serviceBase = 'https://minibots.kpmgdemos.com/api/';

  constructor(private http: HttpClient) {
    const self = this;

    self.session_id = UUID.UUID();
    self.chat_url = `${this.serviceBase}chat`;
  }

  reset_state() {
    const self = this;

    self.session_id = UUID.UUID();
  }

  set_session_language(userInput: string) {
    // TODO; cool generic way to derive language from a possibly unstructured string
    // ex; Let's speak English, vs Hablar en español
    const input = userInput.toLowerCase();
    if (input.indexOf('english') >= 0) {
      this.session_language = 'en-US';
    }

    if (input.indexOf('espanol') >= 0 || input.indexOf('español') >= 0) {
      this.session_language = 'es';
    }

  }

  send_message(message = '', project: string) {

    if (this.output_contexts.indexOf('set-session-language') >= 0) {
      this.set_session_language(message);
    }

    return this.http.post(this.chat_url, {
      'message': message,
      'dialogflowProjectId': project,
      'sessionId': this.session_id,
      'languageCode': this.session_language,
      // 'dialogflowKnowledgeBases': ['MTcwMDI0OTU5NjgyMDcxNzU2OA']
    }, {
      withCredentials: true
    });
  }

  getBotConfig(projectName: string) {

    return this.http.get(`${this.serviceBase}${projectName}/config`)
      .pipe(
        take(1)
      );
  }

  getAllBotConfigs() {
    return this.http.get(`${this.serviceBase}bots`);
  }

  verifyPassword(projectName: string, password: string) {
    return this.http.post(`${this.serviceBase}${projectName}/verify-password`, { password });
  }

}
