import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { ChatMessagesService } from '../chat-messages.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  projectName: string;
  allBotConfigs: BotConfig[];
  destroy = new Subject<any>();
  botConfig: BotConfig;
  configChecked = false;

  constructor(private activatedRoute: ActivatedRoute, private chatMessageService: ChatMessagesService) {
  }

  ngOnInit() {

    this.activatedRoute.paramMap
      .pipe(
        takeUntil(this.destroy)
      )
      .subscribe((params: ParamMap) => {
        this.projectName = params.get('projectName');

        if (!this.projectName) {
          this.showAllBots();
        }

      this.chatMessageService.getAllBotConfigs()
        .pipe(
          takeUntil(this.destroy)
        )
        .subscribe((botConfigs: BotConfig[]) => {
          this.botConfig = botConfigs.find((bot) => bot.name === this.projectName);
          this.configChecked = true;
        });

      });

  }

  showAllBots() {

    return;

    this.chatMessageService.getAllBotConfigs()
      .pipe(
        map((botConfigs: BotConfig[]) => {

          // sort the configs alphabetically
          return botConfigs.sort((bc1, bc2) => {
            const nameA = bc1.name.toLowerCase();
            const nameB = bc2.name.toLowerCase();

            if (nameA < nameB) {
              return -1;
            }

            if (nameA > nameB) {
              return 1;
            }

            return 0;
          });
        }),
        takeUntil(this.destroy)
      )
      .subscribe((botConfigs: BotConfig[]) => {
        this.allBotConfigs = botConfigs;
      });

  }

  ngOnDestroy() {
    this.destroy.next();
  }
}
