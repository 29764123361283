import { Component, OnInit, Input, ViewChild, TemplateRef, ElementRef, EventEmitter, NgZone, OnDestroy, Inject, RendererFactory2, ViewEncapsulation } from '@angular/core'; // tslint:disable-line max-line-length
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ChatMessagesService } from '../chat-messages.service';
import * as _ from 'lodash';
import { takeUntil, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IntentResponse, ChatResponse, CardButton, Card, QuickReplies, Reply, ListSelect, Suggestions, Suggestion, ResponseImage, LinkOutSuggestion } from './models/chat-response'; // tslint:disable-line max-line-length
import { DOCUMENT } from '@angular/common';

interface IWindow extends Window {
  webkitSpeechRecognition: any;
  SpeechRecognition: any;
}

// Find requested widgets from an intent's output contexts by matching contexts prefixed with
// 'widget-'.
//
// NOTE: Only word characters and dashes are expected in the widget name.
const findWidgets = function(intent: IntentResponse): string {
  const widgets = [];
  (intent.outputContexts || []).forEach((ctx) => {
    const matches = /\/(widget-[\w-]+)$/.exec(ctx.name);

    if (matches && matches[1]) {
      widgets.push({
        widgetName: matches[1],
        lifespanCount: ctx.lifespanCount,
      });
    }
  });

  if (widgets.length >= 1) {
    // Only return the widget with the lowest `lifespanCount`
    const sortedWidgets = _.sortBy(widgets, 'lifespanCount');
    return sortedWidgets[0].widgetName;
  }

  return null;
};

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  animations: [
    trigger('scaleIn', [
      transition(':enter', [
        style({ height: '0%', minHeight: '0px' }),
        animate('325ms ease-out', style({ height: '100%', minHeight: '500px' }))
      ]),
      transition(':leave', [
        style({ height: '100%', minHeight: '500px' }),
        animate('325ms ease-out', style({ height: '0%', minHeight: '0px' }))
      ])
    ]),
    trigger('fadeIn', [
      transition('void => pending', [
        style({ height: '0px', opacity: 0.0 }),
        animate('10ms 1000ms', style({ height: '*', opacity: 0.0 })),
        animate('500ms', style({ opacity: 1.0 }))
      ]),
      transition('void => non-pending', [
        style({ opacity: 0.0 }),
        animate('500ms', style({ display: 'list-item', opacity: 1.0 }))
      ]),
      transition('pending => non-pending', [
        animate('500ms', style({ display: 'list-item', opacity: 1.0 }))
      ])
    ])
  ]
})

// animate ('500ms 5500ms', style ({ display: 'list-item', opacity: 1.0 }))
export class ChatComponent implements OnInit, OnDestroy {

  @ViewChild('content')
  content: TemplateRef<any>;

  @ViewChild('dialogue')
  dialog: ElementRef;

  @ViewChild('chatlist')
  chatlist: ElementRef;

  @Input()
  projectName: string;

  projectId: string;
  name: string;
  title: string;
  stripPII: boolean;
  password: string;
  initialQuery: string;
  passwordRequired: boolean;
  submittedPassword: string;
  invalidProjectName: boolean;
  showPasswordScreen: boolean;
  invalidPassword: boolean;
  showSpinner: boolean;

  stillOpen: boolean;
  closingPromise: Promise<boolean>;

  modal_close_transition_event: EventEmitter<any> = new EventEmitter();

  number_of_retries: number;

  scroller: any;
  scroll_ratio: number;
  scroll_threshold: number;
  scroll_in_progress: boolean;
  scroll_allowed: boolean;

  queue: Array<any>;
  initial_question: string;
  ticketable_message: string;
  current_response: string;
  pending_responses: Array<number>;
  subscriptions: Array<any>;

  ssn_check: RegExp;

  // Voice Input
  final_transcript: string;
  listening: boolean;
  recognizing: boolean;
  recognition: any;

  destroy = new Subject<any>();

  constructor(
    private route: ActivatedRoute,
    private chatMessagesService: ChatMessagesService,
    private zone: NgZone,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document,
  ) {
    const self = this;
    self.zone = zone;
    self.ssn_check = new RegExp('(?!(000|666|9))\\d{3}-(?!00)\\d{2}-(?!0000)\\d{4}|(?!(000|666|9))\\d{3}(?!00)\\d{2}(?!0000)\\d{4}', 'g');
  }

  get ready_to_send(): boolean {
    const self = this;

    return (_.isEmpty(self.current_response) === false);
  }

  ngOnInit() {
    const self = this;

    this.loadBotConfig();

    const { webkitSpeechRecognition }: IWindow = <any>window;

    if (webkitSpeechRecognition) {
      self.recognition = new webkitSpeechRecognition();

      // Voice Input
      this.initSpeechRecognition();
    }

    self.reset_state();
  }

  private initSpeechRecognition() {

    this.recognition.onstart = () => {
      this.recognizing = true;
    };

    this.recognition.onerror = (event) => {
      if (event.error === 'no-speech') {
        this.add_to_queue('No speech detected.', 'warning', 'no_speech');
      }
      if (event.error === 'audio-capture') {
        this.add_to_queue('There is an issue with your audio capture device.', 'warning', 'audio_capture');
      }
      if (event.error === 'not-allowed') {
        this.add_to_queue('Audio capture was not given permission to start recording.', 'warning', 'not_allowed');
      }
      this.recognizing = false;
      this.listening = false;
    };

    this.recognition.onend = () => {
      if (!this.final_transcript) {
        return;
      }
      this.recognizing = false;
      this.listening = false;
    };

    this.recognition.onresult = (event) => {
      let transcript = '';
      this.recognizing = true;

      for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          this.final_transcript += event.results[i][0].transcript;
          this.stop_recognition();
          return;
        } else {
          transcript += event.results[i][0].transcript;
        }
      }

      this.zone.run(() => this.current_response = transcript);
    };
  }

  loadBotConfig() {
    this.showSpinner = true;

    this.chatMessagesService.getBotConfig(this.projectName)
      .pipe(
        finalize(() => this.showSpinner = false),
        takeUntil(this.destroy)
      )
      .subscribe((botConfig: BotConfig) => {

        if (botConfig) {
          this.projectId = botConfig.projectId;
          this.name = botConfig.name;
          this.title = botConfig.title;
          this.stripPII = botConfig.stripPII;
          this.initialQuery = this.route.snapshot.queryParams.q || botConfig.initialQuery;
          this.passwordRequired = botConfig.passwordProtected;

          const renderer = this.rendererFactory.createRenderer(this.document, {
            id: '-1',
            encapsulation: ViewEncapsulation.None,
            styles: [],
            data: {}
          });

          const head = this.document.head;
          if (head && botConfig.cssPath) {

            const link = renderer.createElement('link');
            renderer.setAttribute(link, 'rel', 'stylesheet');
            renderer.setAttribute(link, 'href', botConfig.cssPath);

            renderer.appendChild(head, link);
          }

          if (botConfig.cssClass) {
            // Add class to `body` tag.
            renderer.addClass(this.document.body, botConfig.cssClass);
          }

          if (this.passwordRequired) {
            this.showPasswordScreen = true;
          } else {
            this.open(this.initialQuery);
          }
        } else {
          this.invalidProjectName = true;
        }
      },
      () => {
        this.invalidProjectName = true;
      });

  }

  submitPassword() {
    this.showSpinner = true;
    const self = this;

    this.chatMessagesService.verifyPassword(this.projectName, this.submittedPassword)
      .pipe(
        finalize(() => this.showSpinner = false),
        takeUntil(this.destroy)
      )
      .subscribe((success: boolean) => {

        if (success) {
          self.showPasswordScreen = false;
          self.open(self.initialQuery);
          this.submittedPassword = '';
        } else {
          self.invalidPassword = true;
        }
      });
  }

  reset_state() {
    const self = this;

    self.stillOpen = true;
    self.number_of_retries = 5;

    self.scroll_ratio = 0.1;
    self.scroll_threshold = 1;
    self.scroll_in_progress = false;
    self.scroll_allowed = false;

    self.queue = [];
    self.initial_question = '';
    self.ticketable_message = '';
    self.current_response = '';
    self.pending_responses = [];
    self.subscriptions = [];

    self.chatMessagesService.reset_state();

    // Voice Input
    self.final_transcript = '';
    self.recognizing = false;
    self.listening = false;

    if (self.recognition) {
      self.recognition.continuous = true;
      self.recognition.interimResults = true;
    }
  }

  open(question: string = '') {
    const self = this;

    self.reset_state();

    self.initial_question = question;
    self.send(true, question);
  }

  close(): Promise<boolean> {
    const self = this;

    self.closingPromise = new Promise((resolve, reject) => {
      const modal_close_transition: any = self.modal_close_transition_event.subscribe(() => {
        resolve(true);
        self.stillOpen = true;
      });
    });
    self.stillOpen = false;
    return self.closingPromise;
  }

  onScaleInDone(event: any) {
    const self = this;

    if (self.stillOpen === false) {
      self.modal_close_transition_event.emit();
    }
  }

  respond() {
    const self = this;

    if (_.isEmpty(self.current_response) === false) {

      // Check for sensitive information
      if (this.stripPII && this.ssn_check.test(self.current_response)) {
        self.add_to_queue('We detected that you may have entered confidential information such as your SSN. <strong>Your message was not sent.</strong>', 'warning', 'not_sent'); // tslint:disable-line max-line-length
      } else if (self.current_response === 'card') {
        self.send(true, self.current_response);
        self.zone.run(() => self.current_response = '');
      } else {
        self.send(false, self.current_response);
        self.zone.run(() => self.current_response = '');
      }

    }
  }

  send(suppress_text: boolean = false, question: string = '', attempt: number = 1) {
    const self = this;

    if (attempt === 1) {
      // Only put user questions into the queue if they are not:
      // - the first (so the first can go into the header), and;
      // - not a retry (due to 500)
      if (suppress_text === false) {
        self.add_to_queue(question, 'user', 'chat');
      }

      // If this is the first attempt, add the thinking spinner
      self.pending_responses.push(self.add_to_queue('&nbsp;', 'bankbot', 'pending'));
    }

    self.subscriptions.push(
      self.chatMessagesService
        .send_message(question, this.projectId)
        .pipe(
          takeUntil(this.destroy)
        )
        .subscribe((response: ChatResponse) => {
          // add a 2 second delay, make conversation a little less robotic / jarring
          setTimeout(async () => {

            // Replace the pending (loading spinner) with the actual response
            const prompt = _.get(response, 'intent.prompt', null);
            if (prompt) {
              self.update_queue(
                self.pending_responses.shift(),
                prompt,
                'bankbot',
                'chat'
              );
            } else {
              // still remove the loading spinner
              self.pending_responses.shift();
            }

            if (response.intent) {

              // Search output contexts for widgets
              const widgetName = findWidgets(response.intent);

              self.chatMessagesService.output_contexts = (response.intent.outputContexts || []).map(c => c.name.split('/').pop());

              if (widgetName) {
                self.add_to_queue(self.ticketable_message, 'bankbot', 'widget', { widget: widgetName });
              }

              let messages = [];

              if (response.intent.fulfillmentMessages && response.intent.fulfillmentMessages.length > 0) {
                messages = response.intent.fulfillmentMessages;
              } else {
                messages.push(response.intent);
              }

              // regular for loop to maintaint outer async context
              for (let m = 0; m < messages.length; m++ ) {
                const message = messages[m];

                if (message.text && message.text.text) {
                  (message.text.text || []).forEach(txt => {
                    if (txt !== prompt) {
                      self.add_to_queue(txt, 'bankbot', 'chat');
                    }
                  });
                }

                // Show the buttons that the user can click on to create a quick reply
                if (message.replies || message.quickReplies) {

                  const quickReplies = message.quickReplies || { quickReplies: message.replies };

                  quickReplies.quickReplies = quickReplies.quickReplies.map((reply: string) => {
                    return <Reply> {
                      type: reply,
                      isSelected: false
                    };
                  });

                  if (quickReplies.title === prompt) {
                    // prompt already displayed, don't repeat it
                    quickReplies.title = '';
                  }

                  this.add_to_queue('', 'bankbot', 'reply', { quickReplies });
                }

                if (message.image && message.image.imageUri) {
                  const size: any = await this.getImageSize(message.image.imageUri);
                  this.add_to_queue('', 'bankbot', 'image', { imageUri: message.image.imageUri, height: size.h, width: size.w });
                }

                if (message.card) {

                  // get the image's width and height
                  if (message.card.imageUri) {
                    const size: any = await this.getImageSize(message.card.imageUri, 220, 250);
                    message.card.imageWidth = size.w;
                    message.card.imageHeight = size.h;
                  }

                  this.add_to_queue('', 'bankbot', 'card', {
                    card: message.card,
                  });
                }

                if (message.basicCard) {

                  // get the image's width and height
                  if (message.basicCard.image) {
                    const size: any = await this.getImageSize(message.basicCard.image.imageUri, 220, 250);
                    message.basicCard.image.width = size.w;
                    message.basicCard.image.height = size.h;
                  }

                  this.add_to_queue('', 'bankbot', 'basicCard', {
                    basicCard: message.basicCard,
                  });
                }

                if (message.listSelect) {

                  message.listSelect.items.forEach(async item => {
                    // need to get the image sizes
                    if (item.image && item.image.imageUri) {
                      const size: any = await this.getImageSize(item.image.imageUri, 120, 150);
                      item.image.width = size.w;
                      item.image.height = size.h;
                    }
                  });

                  this.add_to_queue('', 'bankbot', 'listSelect', {
                    listSelect: message.listSelect,
                  });
                }

                if (message.suggestions && message.suggestions.suggestions) {

                  // map these to CardButtons since the functionality is the same
                  const cardButtons = message.suggestions.suggestions.map((suggestion) => {
                    return <CardButton> {
                      text: suggestion.title,
                      postback: suggestion.title
                    };
                  });

                  this.add_to_queue('', 'bankbot', 'suggestions', {
                    suggestions: cardButtons
                  });

                }

                if (message.linkOutSuggestion) {
                  this.add_to_queue('', 'bankbot', 'link', {
                    linkOutSuggestion: message.linkOutSuggestion
                  });
                }

                if (m < messages.length) {
                  await this.pause(.75);
                }
              }

            }

          }, 2000);
        },
          (err) => {

            if (err.status === 401) {
              self.showPasswordScreen = true;
              self.pending_responses.shift();
            } else {
              // Error in the response, either retry up to the number of allowed attempts, else issue the failure message
              if (attempt <= self.number_of_retries) {
                self.send(false, question, (attempt + 1));
              } else {
                // TODO: This is usually a 500 response, need some better handling to indicate to the user
                self.pending_responses.shift();
                self.add_to_queue('ERROR re: "' + question + '"', 'bankbot', 'chat');
              }
            }
          },
          () => { }
        )
    );
  }

  // triggered when one of the quick reply buttons is clicked
  triggerReply(reply: Reply, replies: Reply[]) {

    if (reply.isDisabled) {
      return;
    }

    this.send(true, reply.type, 1);
    reply.isSelected = true;

    // disable all of the replies since one was selected
    replies.forEach(r => r.isDisabled = true);
  }

  // triggered when one of the card buttons are selected
  triggerCardPostback(cardButton: CardButton, cardButtons: CardButton[]) {

    if (cardButton.isDisabled) {
      return;
    }

    this.send(true, cardButton.postback, 1);
    cardButton.isSelected = true;

    // disable all of the buttons since one was selected
    cardButtons.forEach(cb => cb.isDisabled = true);
  }

  async getImageSize(url, maxWidth?: number, maxHeight?: number) {
    const promise = new Promise((resolve, reject) => {
      const image = new Image();
      const maxHeightByScale = this.dialog.nativeElement.clientHeight * 0.7;
      const maxWidthByScale = this.chatlist.nativeElement.clientWidth - 80; // 50px left padding, 30 padding on .container

      try {
        image.onload = () => {

          const size = {
            h: image.naturalHeight,
            w: image.naturalWidth
          };

          const scale = Math.min(maxWidthByScale / size.w, maxHeightByScale / size.h);

          size.h *= scale;
          size.w *= scale;

          if (maxWidth !== undefined && size.w > maxWidth) {
            size.w = maxWidth;
          }

          if (maxHeight !== undefined && size.h > maxHeight) {
            size.h = maxHeight;
          }

          resolve(size);
        };

        image.src = url;
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });

    return promise;
  }

  create_service_ticket(index: number, timestamp: number, issue: any, category: any) {
    const self = this;

    if (self.ready_to_create_service_ticket(issue, category) === true) {
      const entered_issue = _.get(issue, 'value', '');
      const selected_category = _.get(category, 'selectedItems[0].label', '');

      // TODO: Wire up a ticket creation API
      // self.serviceTicketService.create({
      //   'text': entered_issue,
      //   'category': selected_category,
      //   'timestamp': timestamp,
      // });

      // Update the queue
      self.update_queue(index, 'Thank you for filing service ticket #BOT2313', 'bankbot', 'chat');
    }
  }

  ready_to_create_service_ticket(issue: any, category: any): boolean {
    const self = this;

    const entered_issue = _.get(issue, 'value', '');
    const selected_category = _.get(category, 'selectedItems[0].label', '');

    return (_.isEmpty(entered_issue) === false && _.isEmpty(selected_category) === false);
  }

  add_to_queue(message: string = '&nbsp;', party: string = 'bankbot', type: string = 'chat',
        extras: {
          quickReplies?: QuickReplies,
          imageUri?: string,
          height?: string,
          width?: string,
          widget?: string,
          card?: Card,
          basicCard?: any,
          listSelect?: ListSelect,
          suggestions?: CardButton[],
          linkOutSuggestion?: LinkOutSuggestion
        } = {}): number {
    const self = this;

    self.scroll_allowed = true;

    self.zone.run(() => {
      self.queue.push({
        'text': message,
        party,
        'timestamp': Date.now(),
        type,
        replies: extras.quickReplies || null,
        imageUri: extras.imageUri || '',
        height: extras.height || '',
        width: extras.width || '',
        widget: extras.widget || '',
        card: extras.card || null,
        basicCard: extras.basicCard || null,
        listSelect: extras.listSelect || null,
        suggestions: extras.suggestions || null,
        linkOutSuggestion: extras.linkOutSuggestion || null
      });
    });

    return (self.queue.length - 1);
  }

  update_queue(position: number = null, message: string = '', party: string = 'bankbot', type: string = 'chat') {
    const self = this;

    if (_.isNull(position) === false) {

      if (message.length === 0) {
        self.queue.splice(position, 1);
      } else {
        self.scroll_allowed = true;
        self.zone.run(() => {
          _.set(self.queue, '[' + position + '].text', message);
          _.set(self.queue, '[' + position + '].party', party);
          _.set(self.queue, '[' + position + '].type', type);
        });
      }
    }
  }

  scroll_dialogue(dialogue: any) {
    const self = this;

    self.scroller = dialogue;

    if (self.scroll_in_progress === false && self.scroll_allowed === true) {
      self.scroll_in_progress = true;
      self.scroll_allowed = false;
      self.scroll();
    }
  }

  scroll() {
    const self = this;

    const difference = ((self.scroller.scrollHeight - self.scroller.offsetHeight) - self.scroller.scrollTop);
    const offset = Math.ceil((difference * self.scroll_ratio));
    self.scroller.scrollTop = self.scroller.scrollTop + offset;

    if (self.scroll_threshold >= difference) {
      self.scroller.scrollTop = (self.scroller.scrollHeight - self.scroller.offsetHeight);
      self.scroll_in_progress = false;
    } else {
      window.requestAnimationFrame(() => self.scroll());
    }
  }

  test(dialogue: any, pending: boolean) {
    const self = this;

    window.console.info('test', dialogue, pending);
    window.console.info(self);

    // self.scroll_allowed = true;
    // self.scroll_dialogue(dialogue);
  }

  // Voice Input
  listen() {
    const self = this;

    if (self.recognizing === true) {
      self.recognition.stop();
      self.final_transcript = '';
      self.listening = false;
      self.recognizing = false;
    } else {
      self.zone.run(() => self.current_response = '');
      self.start_recongnition();
    }
  }

  start_recongnition() {
    const self = this;

    self.listening = true;
    self.final_transcript = '';
    self.recognition.lang = 'english';
    self.recognition.start();
  }

  stop_recognition() {
    const self = this;

    self.listening = false;

    if (self.recognizing === true) {
      self.recognition.stop();

      // NOTE: At present, the voice parser cannot handle over 256 bytes
      // Both this and the text input are limited to 256 characters
      self.current_response = self.final_transcript.substring(0, 255);
      self.respond();
    }
  }

  signatureReady() {
    this.send(true, 'SIGNATURE_ACCEPTED');
  }

  async pause(seconds) {
    const promise = new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, seconds * 1000);
    });
    return promise;
  }

  ngOnDestroy() {
    this.destroy.next();
  }
}
